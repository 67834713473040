
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ToolTip',
    props: {
        text: {
            type: String,
            default: ''
        },
        left: {
            type: String,
            default: '50%'
        },
        arrowLeft: {
            type: String,
            default: '50%'
        }
    }
});
