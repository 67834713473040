
import { defineComponent } from 'vue';
import ToolTip from '@/components/ToolTip.vue';

export default defineComponent({
    name: 'App',
    components: { ToolTip },
    data() {
        return {
            breadcrumbs: [],
            pageTransition: 'fade',
            darkMode: false,
            mobileMenu: false
        };
    },
    watch: {
        $route() {
            this.breadcrumbs = this.$route.meta.crumbs;
        }
    },
    mounted() {
        this.breadcrumbs = this.$route.meta.crumbs;
        if (localStorage && localStorage.getItem('darkMode')) {
            this.darkMode = localStorage.getItem('darkMode') === 'true';
        } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) this.darkMode = true;
    },
    methods: {
        changeDarkMode() {
            this.darkMode = !this.darkMode;
            localStorage.setItem('darkMode', this.darkMode.toString());
        }
    },
    created() {
        this.$router.beforeEach((to, from, next) => {
            this.mobileMenu = false;
            if (to.name === 'Home') {
                this.pageTransition = 'slide-left';
            } else if (from.name === 'Home') {
                this.pageTransition = 'slide-right';
            } else if (to.name === 'About') {
                this.pageTransition = 'slide-left';
            } else if (to.name === 'Projects' && from.name === 'About') {
                this.pageTransition = 'slide-right';
            } else if (to.name === 'Projects' && from.name === 'Resume') {
                this.pageTransition = 'slide-left';
            } else if (to.name === 'Resume' && from.name === 'About') {
                this.pageTransition = 'slide-right';
            } else if (to.name === 'Resume' && from.name === 'Projects') {
                this.pageTransition = 'slide-right';
            } else {
                this.pageTransition = 'fade';
            }
            return next();
        });
    }
});
